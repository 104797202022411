<template>
	<div class="indexHome" v-loading="loading">
		<div class="tops_box">
			<div class="lefts" @click="$router.push('/Employee_Information')">
				<img src="../assets/img/index/logos.png" alt="">
			</div>
			<div class="rights">
				<div class="xiaoxi" @click="$router.push('/messages')">
					<img src="../assets/img/index/tixing.png" alt="">
					<div class="xx_num" v-if="vuex_unread_number > 0">{{vuex_unread_number}}</div>
				</div>
				<div class="user_info" @click="$router.push('/edit_info')">
					<div class="imgs">
						<img :src="user_info.image || avatar_default" alt="">
					</div>
					<div class="name">{{user_info.name}}</div>
				</div>
				<div class="btns_flex">
					<div class="btns1" @click="$router.push('/changePassword')">修改密码</div>
					<div class="bordes"></div>
					<div class="btns2" @click="do_logout()">退出登录</div>
				</div>
			</div>
		</div>
		<div class="boms_box">
			<div class="lefts">
				<!--  @open="handleOpen"
      @close="handleClose" -->
				<el-menu :router="true" class="el-menu-vertical-demo" :unique-opened="true" background-color="#000C17"
					text-color="#969EB8" @open="handleOpen" @close="handleClose" :default-active="routers"
					active-text-color="#FFFFFF">
					<el-submenu :index="index+''" v-for="(item,index) in NavigateItem" :key="index" v-if="index1_show[index]">
						<template slot="title">
							<!-- <i class="el-icon-location"></i> -->
							<img :src="item.image" alt="" class="tab_img">
							<span>{{ item.title }}</span>
						</template>
						<!-- 一级 -->
						<!-- <el-menu-item-group> -->
							<el-menu-item :index="items.path" :route="items.path" v-for="(items,indexs) in item.list"
								v-if="!items.list&&qx_list.find(elem => elem ==items.type)" :key="indexs">{{ items.title }}</el-menu-item>
						<!-- </el-menu-item-group> -->
						<!-- 二级 -->
						<el-submenu :index="items.path" v-for="(items,indexs) in item.list" :key="items.path" v-if="items.list&&(index2_show[index]||[])[indexs]">
							<template slot="title">{{ items.title }} </template>
							<el-menu-item :index="items2.path" :route="items2.path" :key="items2.path"
								v-for="(items2,indexs2) in items.list" v-if="qx_list.find(elem => elem ==items2.type)">{{ items2.title }}</el-menu-item>
						</el-submenu>
					</el-submenu>

				</el-menu>
			</div>
			<div class="rights">
				<div class="path_view">
	  <!-- {{ routers }} -->

					<router-view></router-view>
				</div>
			</div>
		</div>


	</div>
</template>
<script>
	import {
		mapState
	} from 'vuex'
	export default {
		components: {

		},
		name: "index",
		data() {
			return {
				avatar_default: require("@/assets/img/avatar.png"),
				loading: false,
				user_info: {}, //用户信息
				user_info2: {}, //
				sx_show: false,
				routers: '',

				// 路由
				NavigateItem: [
				{
					title: '员工管理',
					type: 1,
					ac_show:false,
					image: require('@/assets/img/index/tab1.png'),
					list: [{
						title: '员工信息',
						path: '/Employee_Information',
						type: '1_1',
						ac_show:false,
					},
					{
						title: '通讯管理',
						path: '/Newsletter_management',
						type: '1_2',
						ac_show:false,
					},
					{
						title: '合同管理',
						path: '/Contract_management',
						type: '1_3',
						ac_show:false,
					},
					{
						title: '员工调动',
						path: '/Employee_transfers',
						type: '1_4',
						ac_show:false,
					},
					{
						title: '组织架构',
						path: '/Organizational_structure',
						type: '1_5',
						ac_show:false,
					},
					{
						title: '岗位列表',
						path: '/post_management',
						type: '1_6',
						ac_show:false,
					}

					]
				},
				{
					title: '薪酬管理',
					type: 2,
					ac_show:false,
					image: require('@/assets/img/index/tab2.png'),
					list: [
						{
							title: '社保管理',
							path: '/Social_security_management',
							type: '2_1',
							ac_show:false,
						},
						{
							title: '薪资设置',
							path: '/Salary_Management',
							type: '2_2',
							ac_show:false,
						},

						{
							title: '员工标准工资设置',
							path: '/Salary_setting',
							type: '2_3',
							ac_show:false,
						},
						{
							title: '工资下发',
							path: '/Salary_distribution',
							type: '2_4',
							ac_show:false,
						},

						{
							title: '薪资台账',
							path: '/Employee_salary',
							type: '2_5',
							ac_show:false,
						},
						{
							title: '发放记录',
							path: '/Employee_salarys',
							type: '2_6',
							ac_show:false,
						},
						// {
						// 	title: '结算月数据',
						// 	path: '/Billing_month_data',
						// }
					]
				},
				{
					title: '考勤管理',
					type: 3,
					ac_show:false,
					image: require('@/assets/img/index/tab3.png'),
					list: [{
						title: '考勤设置',
						path: '/Attendance_settings',
						type: '3_1',
						ac_show:false,
						list: [{
							title: '考勤规则',
							path: '/Attendance_settings/Attendance_rules',
							type: '3_1_1',
							ac_show:false,
						},
						{
							title: '免考勤人员设置',
							path: '/Attendance_settings/Attendance_no',
							type: '3_1_2',
							ac_show:false,
						},
						{
							title: '下井设置',
							path: '/Attendance_settings/Downhole_settings',
							type: '3_1_3',
							ac_show:false,
						},
						{
							title: '节假日设置',
							path: '/Attendance_settings/Holiday_settings',
							type: '3_1_4',
							ac_show:false,
						},
						]
					},
					{
						title: '考勤数据',
						path: '/Attendance_data',
						type: '3_2',
						ac_show:false,
						list: [{
							title: '每日考勤',
							path: '/Attendance_data/Daily_statistics',
							type: '3_2_1',
							ac_show:false,
						},
						{
							title: '月度考勤',
							path: '/Attendance_data/Monthly_rollups',
							type: '3_2_2',
							ac_show:false,
						}
						]
					}
						// {
						// 	title: '每日统计',
						// 	path: '/Daily_statistics',
						// },
						// {
						// 	title: '月度汇总',
						// 	path: '/Monthly_rollups',
						// },
						// {
						// 	title: '历史查询',
						// 	path: '/Historical_queries',
						// }
					]
				},
				{
					title: '员工福利',
					type: 4,
					ac_show:false,
					image: require('@/assets/img/index/tab4.png'),
					list: [
						{
							title: '礼品管理',
							path: '/Gift_management',
							type: '4_1',
							ac_show:false,
						},
						{
							title: '礼品下发',
							path: '/Gift_distributed',
							type: '4_2',
							ac_show:false,
						}
					]
				},
				{
					title: '劳保用品',
					type: 5,
					ac_show:false,
					image: require('@/assets/img/index/tab5.png'),
					list: [
						{
							title: '劳保用品管理',
							path: '/Labor_Management',
							type: '5_1',
							ac_show:false,
						},
						{
							title: '劳保用品下发',
							path: '/Labor_distributed',
							type: '5_2',
							ac_show:false,
						},
						// {
						// 	title: '领取劳保用品',
						// 	path: '/Labor_Receive',
						// 	type: '5_3',
						// 	ac_show:false,
						// }
					]
				},
				{
					title: '统计报表',
					type: 6,
					ac_show:false,
					image: require('@/assets/img/index/tab6.png'),
					list: [{
						title: '实时报表',
						path: '/Real_time_reports',
						type: '6_1',
						ac_show:false,
					},
					{
						title: '月报预览',
						path: '/monthly_report',
						type: '6_2',
						ac_show:false,
					},
					{
						title: '历史月报',
						path: '/Historical_Monthly',
						type: '6_3',
						ac_show:false,
					}
					]
				},
				{
					title: '账号管理',
					type: 7,
					ac_show:false,
					image: require('@/assets/img/index/tab7.png'),
					list: [{
						title: '账号管理',
						path: '/Account_management',
						type: '7_1',
						ac_show:false,
					},
					{
						title: '角色管理',
						path: '/Role_management',
						type: '7_2',
						ac_show:false,
					},
					{
						title: '系统设置',
						path: '/system_setting',
						type: '7_3',
						ac_show:false,
					},
					{
						title: '操作日志',
						path: '/History_recording',
						type: '7_4',
						ac_show:false,
					}
					]
				},
			],
				qx_list:[]
			}
		},
		computed: {
			...mapState(['vuex_user', 'vuex_unread_number']),
			index1_show(){
				let show=[]
				// qx_list
				this.NavigateItem.forEach((e,i)=>{
					show.push(false)

					let type_list=[]
					e.list.forEach((e1)=>{
						if(e1.list){
							e1.list.forEach((e2)=>{
								// type_list.push(e2.type)
								let show_ac=this.qx_list.find(elem => elem ==e2.type);
								if(show_ac){
									show[i]=true			
								}
							})
						}else{
							let show_ac=this.qx_list.find(elem => elem ==e1.type);
								if(show_ac){
									show[i]=true			
								}
							// type_list.push(e1.type)
						}
					})
				})
				return show
			},
			index2_show(){
				let show=[]
				// qx_list
				this.NavigateItem.forEach((e,i)=>{
					show.push([])
					let show2=[]
					e.list.forEach((e1,e1i)=>{
						show2.push(false)
						if(e1.list){
							let show3=false
							e1.list.forEach((e2)=>{
								let show_ac=this.qx_list.find(elem => elem ==e2.type);
								if(show_ac){
									show2[e1i]=true
								}
							})
							show[i]=show2
						}
					})
				})
				console.log('计算',show)
				return show
			}
		},
		
		
		watch: {
			$route: {
				handler(newVal, oldVal) {
					this.get_user()
					// console.log('$route', this.$route)
					this.routers = this.$route.path
					// this.routers = '/' + this.$route.path.split('/')[1]

				},
				immediate: true,
				deep: true
			},
		},
		created() {
			this.get_setting()
		},
		methods: {
			get_setting(){
				 this.$store.dispatch('get_type_setting')
			},
			handleOpen(key, keyPath) {
				// console.log(key, keyPath);
				// console.log(this.$route)
				this.routers = '/' + this.$route.path.split('/')[1]
			},
			handleClose(key, keyPath) {
				console.log('跳转', key, keyPath);
			},
			 
			//权限判断
			get_user() {
				// this.NavigateItem = []
				console.log('用户信息',localStorage.getItem('cache_user'))
				this.loading = true
				let data={}
				if(localStorage.getItem('cache_user')){
					data=JSON.parse(localStorage.getItem('cache_user'))
					this.user_info=data
					// this.$store.dispatch("appInit"); //重新初始化
				}
				this.$api("getAdminRole", {
					id:data.role_id
				}, "get").then((res) => {
					this.loading = false
					console.log('菜单列表',res)
					if (res.code == 200) {
						// this.qx_list=res.data.roles.split(',')
						this.qx_list=JSON.parse(res.data.roles)
						// this.NavigateItem.forEach((e)=>{
						// 	e.image=require(e.image) 
						// })
						// image

					} else {
						alertErr(res.msg)
					}
				});


			},

			//个人打开
			visible_change(e) {
				console.log('触发', e)
				this.sx_show = e
			},

			// 退出
			handleClose_t() {
				this.$refs.logo_to.handle_open()


				// this.$confirm(this.$t('btn_tc'),'',{
				//    confirmButtonText: this.$t('btn_qr'),
				//     cancelButtonText: this.$t('btn_qx'),
				//     }).then(_ => {
				//     // done();
				//     localStorage.clear()
				//     sessionStorage.clear()
				//     this.$router.push('/')
				//   })
				//   .catch(_ => { });
			},



			//
			do_logout() {
				alertSucc('已退出')
				this.$router.push('/login')
				this.$store.commit('clear_vuex_user')
			},


		},

	}
</script>
<style scoped lang="less">
	.indexHome {
		width: 100%;
		height: 100vh;

		.tops_box {
			width: 1920px;
			height: 83px;
			background: #FFFFFF;
			box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.05);
			border-radius: 0px 0px 0px 0px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 23px;
			padding-right: 31px;

			.lefts {
				img {
					width: 290px;
					height: 58rpx;
					cursor: pointer;
				}
			}

			.rights {
				display: flex;
				align-items: center;

				.xiaoxi {
					position: relative;
					margin-right: 30px;
					cursor: pointer;

					img {
						width: 25px;
						height: 30px;
					}

					.xx_num {
						position: absolute;
						padding: 2px 4px;
						font-family: Arial, Arial;
						font-weight: 400;
						font-size: 12px;
						color: #FFFFFF;
						background: red;
						border-radius: 50%;
						right: -10px;
						top: -5px;
					}
				}

				.user_info {
					display: flex;
					align-items: center;
					cursor: pointer;

					.imgs {
						width: 27px;
						height: 27px;
						border-radius: 50%;
						overflow: hidden;
						img {
							width: 27px;
							height: 27px;
						}
					}

					.name {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						padding: 0 10px;
					}
				}

				.btns_flex {
					display: flex;
					align-items: center;
					padding-left: 16px;

					.btns1 {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						cursor: pointer;
					}

					.bordes {
						height: 14px;
						border-left: 2px solid #333333;
						margin: 0 10px;
					}

					.btns2 {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						cursor: pointer;
					}

					.btns1:hover {
						color: #3697FD;
					}

					.btns2:hover {
						color: #3697FD;
					}

				}
			}
		}

		.boms_box {
			display: flex;
			// align-items: center;
			height: 100%;

			.lefts {
				width: 230px;
				height: 100%;
				background: #000C17;
				flex-shrink: 0;
				padding-right: 2px;
				/deep/.tab_img {
					width: 20px;
					height: 20px;
					margin-right: 11px;
				}
				/deep/.el-menu {
					border-color: #000C17;
				}
			}

			.rights {
				flex: 1;

				// height: 95vh;
				.path_view {
					height: 820px;
					overflow-y: scroll;
				}

				background: #F3F3F3;
				padding: 20px 30px 0 27px;
			}
		}

	}
</style>